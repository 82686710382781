const colors = {
  white: '#ffffff',
  black: '#010101',
  cloud: '#E4E6EB',
  paper: '#EEEFF2',
  meteorite: '#424755',
  neverything: '#121619',
  sambucus: '#101828',
  river: '#344054',
  lavender: '#e4e0ec',
  gloomyPurple: '#7f56d9',
  forgottenPurple: '#9276ff',
  springThaw: '#d9dbdc',
  shadow: '#777E91',
  purple: {
    50: '#F9F5FF',
    100: '#F3EAFF',
    200: '#E9D7FE',
    300: '#D6BBFB',
    400: '#CDADFF',
    500: '#8331FF',
    600: '#5E24B9',
    700: '#6225BF',
    800: '#4F1E99',
    900: '#3C1773',
  },
  primary: {
    25: '#FCFAFF',
    50: '#F9F5FF',
    100: '#F3EAFF',
    200: '#E9D7FE',
    300: '#D6BBFB',
    400: '#CDADFF',
    500: '#A86FFF',
    600: '#8331FF',
    700: '#6225BF',
    800: '#4F1E99',
    900: '#3C1773',
  },
  gray: {
    25: '#F9F9F9',
    50: '#F2F4F5',
    100: '#E3E5E5',
    200: '#BBBBBB',
    300: '#666666',
    400: '#3F3F3F',
    500: '#23262F',
    600: '#010101',
    700: '#000000',
    900: '#101828',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
};

export default colors;
