import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import textStyles from './textstyles';
import colors from './colors';
import breakpoints from './breakpoints';
import Button from './components/Button';
import Input from './components/Input';
import Select from './components/Select';
import { skeletonTheme } from './components/Skeleton';
import { alertTheme } from './components/Alert';
import { modalTheme } from './components/Modal';
import { menuTheme } from './components/Menu';

const defaultProps = {
  defaultProps: {
    colorScheme: 'purple',
  },
};

const tootip = {
  baseStyle: {
    padding: '8px 12px',
    background: 'sambucus',
    borderRadius: '8px',
  },
};

// pass your custom values
const theme = extendTheme({
  styles,
  colors,
  textStyles,
  components: {
    Button,
    Input,
    Select,
    Checkbox: defaultProps,
    Radio: defaultProps,
    Switch: defaultProps,
    Tag: {
      ...defaultProps,
      variants: {
        subtle: (props) => ({
          container: {
            bg: props.colorScheme + '.50',
            color:
              props.colorScheme === 'gray'
                ? props.colorScheme + '.500'
                : props.colorScheme + '.700',
          },
        }),
      },
    },
    Tooltip: tootip,
    Skeleton: skeletonTheme,
    SkeletonCircle: skeletonTheme,
    SkeletonText: skeletonTheme,
    Alert: alertTheme,
    Modal: modalTheme,
    Menu: menuTheme,
  },
  breakpoints,
});

export default theme;
