import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys
);

const warningVariant = definePartsStyle({
  container: {
    border: '1px solid',
    borderColor: 'warning.300',
    background: 'warning.25',
    borderRadius: '8px',
    // Let's also provide dark mode alternatives
    _dark: {
      borderColor: 'gray.600',
      background: 'gray.800'
    }
  },
  title: {
    fontSize: '14px',
    lineHeight: '18px',
    fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`, // change the font family
    fontWeight: 500,
    color: 'warning.700', // change the input text color
    _dark: {
      color: 'gray.100'
    }
  }
});

export const alertTheme = defineMultiStyleConfig({
  variants: { warning: warningVariant }
});
