import colors from '../colors';

const disabledBtnStyles = {
  opacity: 1,
  bg: colors.gray[50],
  color: colors.gray[200],
};

const Button = {
  baseStyle: {
    fontWeight: 500,
    borderRadius: '8px',
    fontSize: '16px',
    height: '44px',
    bg: 'primary.600',
    color: 'white',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },
  sizes: {
    xs: (props) => ({
      fontWeight: 500,
      height: props.height ? props.height : '36px !important',
      fontSize: '14px',
      padding: '8px 12px',
      width: props.withicon === 'true' && '36px',
    }),
    sm: (props) => ({
      fontWeight: 500,
      height: props.height ? props.height : '40px !important',
      fontSize: '14px',
      padding: '8px 12px',
      width: props.withicon === 'true' && '40px',
    }),
    md: (props) => ({
      fontWeight: 500,
      height: props.height ? props.height : '44px !important',
      fontSize: '16px',
      padding: '12px 20px',
      width: props.withicon === 'true' && '50px',
    }),
    lg: (props) => ({
      fontWeight: 500,
      height: props.height ? props.height : '56px !important',
      fontSize: '16px',
      padding: '20px 24px',
      width: props.withicon === 'true' && '75px',
    }),
    xl: (props) => ({
      fontWeight: 500,
      height: props.height ? props.height : '60px !important',
      fontSize: '18px',
      padding: '20px 24px',
      width: props.withicon === 'true' && '80px',
    }),
  },
  variants: {
    solid: (props) => ({
      bg:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      color: 'white',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      _hover: {
        _disabled: disabledBtnStyles,
        textDecoration: 'none',
        bg:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _focus: {
        bg:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
        color: 'white',
        textDecoration: 'none',
        boxShadow:
          props.colorScheme === 'primary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px  ${colors.primary[50]}`
            : props.colorScheme === 'secondary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.gray[50]}`
            : `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px  ${colors.primary[50]}`,
      },
      _disabled: disabledBtnStyles,
    }),
    outline: (props) => ({
      bg: 'transparent',
      color:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      border:
        props.colorScheme === 'primary'
          ? `1px solid ${colors.primary[600]}`
          : props.colorScheme === 'secondary'
          ? `1px solid ${colors.gray[500]}`
          : `1px solid ${colors.primary[600]}`,
      borderColor:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      _hover: {
        textDecoration: 'none',
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
        border:
          props.colorScheme === 'primary'
            ? `1px solid ${colors.primary[600]}`
            : props.colorScheme === 'secondary'
            ? `1px solid ${colors.gray[500]}`
            : `1px solid ${colors.primary[600]}`,
        borderColor:
          props.colorScheme === 'primary'
            ? 'primary.600'
            : props.colorScheme === 'secondary'
            ? 'gray.500'
            : 'primary.600',
      },
      _focus: {
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
        boxShadow:
          props.colorScheme === 'primary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`
            : props.colorScheme === 'secondary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.gray[50]}`
            : `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`,
      },
      _disabled: disabledBtnStyles,
    }),
    accent: (props) => ({
      bg:
        props.colorScheme === 'primary'
          ? 'primary.50'
          : props.colorScheme === 'secondary'
          ? 'gray.50'
          : 'primary.50',
      color:
        props.colorScheme === 'primary'
          ? 'primary.700'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.700',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      _hover: {
        _disabled: disabledBtnStyles,
        textDecoration: 'none',
        bg:
          props.colorScheme === 'primary'
            ? 'primary.100'
            : props.colorScheme === 'secondary'
            ? 'springThaw'
            : 'primary.100',
      },
      _focus: {
        bg:
          props.colorScheme === 'primary'
            ? 'primary.50'
            : props.colorScheme === 'secondary'
            ? 'gray.50'
            : 'primary.50',
        boxShadow:
          props.colorScheme === 'primary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`
            : props.colorScheme === 'secondary'
            ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.gray[50]}`
            : `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`,
      },
      _disabled: disabledBtnStyles,
    }),
    ghost: (props) => ({
      bg: 'transparent',
      color:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      boxShadow: 'none',
      _hover: {
        textDecoration: 'none',
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _focus: {
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _disabled: disabledBtnStyles,
    }),
    link: (props) => ({
      bg: 'transparent',
      color:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      boxShadow: 'none',
      textDecoration: 'underline',
      _hover: {
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _focus: {
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _disabled: {
        opacity: 0.5,
      },
    }),
    naked: (props) => ({
      bg: 'transparent',
      color:
        props.colorScheme === 'primary'
          ? 'primary.600'
          : props.colorScheme === 'secondary'
          ? 'gray.500'
          : 'primary.600',
      boxShadow: 'none',
      _focus: {
        color:
          props.colorScheme === 'primary'
            ? 'primary.700'
            : props.colorScheme === 'secondary'
            ? 'gray.600'
            : 'primary.700',
      },
      _disabled: {
        opacity: 0.5,
      },
    }),
  },
};
export default Button;
