import React from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { IoIosArrowForward } from 'react-icons/io';
import Button from '../Forms/Button';
import Text from '../Text';
import Link from '../Forms/Link';
export default function EmptyState(props) {
  const { heading, message, linkText, href, background, color, isError, onClick, replace } = props;

  return (
    <Center>
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        align="center"
        background={background}
        borderRadius={'16px'}
        width="100%"
        padding="32px"
        maxW="1440px"
      >
        <Box color="gray.500" pb={'20px'}>
          {isError ? (
            <Image src={'/assets/alert-warning.svg'} alt={'warning'} />
          ) : (
            <Image src={'/assets/alert-icon.svg'} alt={'info'} />
          )}
        </Box>

        {heading && (
          <Text textStyle="bold.10" textAlign="center">
            {heading}
          </Text>
        )}
        {message && (
          <Text
            textStyle="regular.12"
            color={color}
            pt={heading && '10px'}
            maxWidth="352px"
            textAlign="center"
          >
            {message}
          </Text>
        )}

        {linkText && (
          <Button
            as={Link}
            colorScheme="secondary"
            variant="solid"
            textStyle={'medium.12'}
            rightIcon={<IoIosArrowForward />}
            href={href}
            onClick={onClick}
            title={linkText}
            marginTop="30px"
            textDecoration="none"
            replace={replace}
            _focus={{ textDecoration: 'none' }}
          />
        )}
      </Flex>
    </Center>
  );
}

EmptyState.defaultProps = {
  message: 'Start today to enjoy the soft life. Enjoy trips and experiences with your people.',
  background: 'gray.25',
  color: 'gray.500',
};

EmptyState.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  linkText: PropTypes.string,
  href: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  isError: PropTypes.bool,
};
