import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Radio as ChakraRadio, RadioGroup, Stack } from '@chakra-ui/react';
import Text from 'components/Text';

const Radio = forwardRef(function Radio(props, ref) {
  const {
    id,
    value,
    name,
    size = 'md',
    direction = 'column',
    options,
    onChange = () => {},
    onBlur = () => {},
    ...rest
  } = props;

  return (
    <RadioGroup
      id={id}
      value={value}
      name={name}
      size={size}
      ref={ref}
      // colorScheme="purple"
    >
      <Stack spacing={4} direction={direction}>
        {options.map((item, index) => (
          <ChakraRadio
            key={`radio-${index}`}
            id={id}
            value={String(item.value)}
            border={'1px solid #666666'}
            borderColor={'gray.300'}
            alignItems={'flex-start'}
            onChange={onChange}
            onBlur={onBlur}
            _hover={{
              border: '1px solid #6225BF',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
            }}
            _active={{
              border: '1px solid #8331FF',
              backgroundColor: '#8331FF',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
            }}
            _focus={{
              border: '1px solid #8331FF',
              backgroundColor: '#8331FF',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
            }}
            _disabled={{
              border: 'none!important',
              backgroundColor: '#F4EBFF !important',
              color: '#ccc !important',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05) !important',
              cursor: 'not-allowed',
              pointerEvents: 'all',
            }}
            {...rest}
          >
            <Text color="#3f3f3f" textStyle="medium.13" marginTop="-0.25em">
              {item.label}
            </Text>
          </ChakraRadio>
        ))}
      </Stack>
    </RadioGroup>
  );
});

export default Radio;

Radio.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
