export default Object.freeze({
  LOGIN: `/api/auth/login`,
  PROFILE: `profile/me`,
  GET_USER_CURRENCY_INFO: `/users/ip/currency`,
  USERS: (id) => `/users/${id}`,
  BUSINESS: (id) => `/businesses/${id}`,
  BUSINESS_EXPERIENCES: (id) => `/businesses/${id}/experiences`,
  BUSINESS_SERVICES: (businessId, serviceId) => `/businesses/${businessId}/services/${serviceId}`,
  BUSINESS_APPROVAL: (id) => `/businesses/${id}/approval`,
  WALLET_WITHDRAWALS: (businessWalletId) => `/business-wallets/${businessWalletId}/withdrawals`,

  EXPERIENCES: (id) => `/experiences/${id}`,
  EXPERIENCE_APPROVAL: (id) => `/experiences/${id}/approval`,
  EXPERIENCE_SCHEDULE: (id, scheduleId) => `/experiences/${id}/schedules/${scheduleId}`,
  EXPERIENCE_SCHEDULE_DETAILS: (scheduleId) => `/experience-schedules/${scheduleId}`,
  EXPERIENCE_SCHEDULES_PAYOUTS: `/experience-schedule-payouts`,
  REQUEST_EXPERIENCE_SCHEDULES_PAYOUTS: (scheduleId) =>
    `/experience-schedules/${scheduleId}/request-payout`,
  EXPERIENCE_SCHEDULE_PAYOUTS: (payoutId, businessId) =>
    `/experience-schedule-payouts/${payoutId}/businesses/${businessId}`,
  APPROVE_EXPERIENCE_SCHEDULE_PAYOUT: (scheduleId) =>
    `/experience-schedule-payouts/${scheduleId}/approve`,
  DECLINE_EXPERIENCE_SCHEDULE_PAYOUT: (scheduleId) =>
    `/experience-schedule-payouts/${scheduleId}/decline`,

  INDUSTRIES: (id) => `/industries/${id}`,
  SERVICES: (id) => `/services/${id}`,
  PROVISIONS: (id) => `/provisions/${id}`,
  LANGUAGES: (id) => `/languages/${id}`,
  WAITLIST: `/waiting-list`,
  SYSTEM_SETTINGS: `/system-setting`,
});
