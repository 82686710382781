const textStyles = {
  bold: {
    //  default 72px
    1: {
      // you can also use responsive styles
      fontSize: {
        base: '40px',
        sm: '58px',
        md: '72px',
      },
      lineHeight: {
        base: '54px',
        sm: '76px',
        md: '90px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 64px
    2: {
      fontSize: {
        base: '38px',
        sm: '54px',
        md: '64px',
      },
      lineHeight: {
        base: '42px',
        sm: '60px',
        md: '70px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 56px
    3: {
      fontSize: {
        base: '36px',
        sm: '46px',
        md: '56px',
      },
      lineHeight: {
        base: '40px',
        sm: '46px',
        md: '56px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 48px
    4: {
      fontSize: {
        base: '32px',
        sm: '38px',
        md: '34px',
        lg: '48px',
      },
      lineHeight: {
        base: '36px',
        sm: '42px',
        lg: '64px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 38px
    5: {
      fontSize: {
        base: '26px',
        sm: '30px',
        md: '38px',
      },
      lineHeight: {
        base: '35px',
        sm: '41px',
        md: '51px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 36px
    6: {
      fontSize: {
        base: '24px',
        sm: '28px',
        md: '36px',
      },
      lineHeight: {
        base: '32px',
        sm: '36px',
        md: '44px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 30px
    7: {
      fontSize: {
        base: '22px',
        sm: '26px',
        md: '30px',
      },
      lineHeight: {
        base: '30px',
        sm: '34px',
        md: '38px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 24px
    8: {
      fontSize: {
        base: '20px',
        sm: '22px',
        md: '24px',
      },
      lineHeight: {
        base: '28px',
        sm: '30px',
        md: '36px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 22px
    9: {
      fontSize: {
        base: '18px',
        sm: '20px',
        md: '22px',
      },
      lineHeight: {
        base: '28px',
        sm: '32px',
        md: '34px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 20px
    10: {
      fontSize: {
        base: '18px',
        md: '20px',
      },
      lineHeight: {
        base: '28px',
        md: '32px',
      },
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 18px
    11: {
      fontSize: '18px',
      lineHeight: '28px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 16px
    12: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 14px
    13: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 12px
    14: {
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 10px
    15: {
      fontSize: '10px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
    //  default 8px
    16: {
      fontSize: '8px',
      fontFamily: `'SatoshiBold', 'DM Sans', sans-serif`,
      fontWeight: 700,
      color: 'gray.500',
    },
  },
  medium: {
    //  default 72px
    1: {
      // you can also use responsive styles
      fontSize: {
        base: '40px',
        sm: '58px',
        md: '72px',
      },
      lineHeight: {
        base: '54px',
        sm: '76px',
        md: '90px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 64px
    2: {
      fontSize: {
        base: '38px',
        sm: '54px',
        md: '64px',
      },
      lineHeight: {
        base: '42px',
        sm: '60px',
        md: '70px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 56px
    3: {
      fontSize: {
        base: '36px',
        sm: '46px',
        md: '56px',
      },
      lineHeight: {
        base: '40px',
        sm: '46px',
        md: '56px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 48px
    4: {
      fontSize: {
        base: '32px',
        sm: '38px',
        md: '34px',
        lg: '48px',
      },
      lineHeight: {
        base: '36px',
        sm: '42px',
        lg: '64px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 38px
    5: {
      fontSize: {
        base: '26px',
        sm: '30px',
        md: '38px',
      },
      lineHeight: {
        base: '35px',
        sm: '41px',
        md: '51px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 36px
    6: {
      fontSize: {
        base: '24px',
        sm: '28px',
        md: '36px',
      },
      lineHeight: {
        base: '32px',
        sm: '36px',
        md: '44px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 30px
    7: {
      fontSize: {
        base: '22px',
        sm: '26px',
        md: '30px',
      },
      lineHeight: {
        base: '30px',
        sm: '34px',
        md: '38px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 24px
    8: {
      fontSize: {
        base: '20px',
        sm: '22px',
        md: '24px',
      },
      lineHeight: {
        base: '28px',
        sm: '30px',
        md: '36px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 22px
    9: {
      fontSize: {
        base: '18px',
        sm: '20px',
        md: '22px',
      },
      lineHeight: {
        base: '28px',
        sm: '32px',
        md: '34px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 20px
    10: {
      fontSize: {
        base: '18px',
        md: '20px',
      },
      lineHeight: {
        base: '28px',
        md: '32px',
      },
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 18px
    11: {
      fontSize: '18px',
      lineHeight: '28px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 16px
    12: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 14px
    13: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 12px
    14: {
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 10px
    15: {
      fontSize: '10px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
    //  default 8px
    16: {
      fontSize: '8px',
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      fontWeight: 500,
      color: 'gray.500',
    },
  },
  regular: {
    //  default 72px
    1: {
      // you can also use responsive styles
      fontSize: {
        base: '40px',
        sm: '58px',
        md: '72px',
      },
      lineHeight: {
        base: '54px',
        sm: '76px',
        md: '90px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 64px
    2: {
      fontSize: {
        base: '38px',
        sm: '54px',
        md: '64px',
      },
      lineHeight: {
        base: '42px',
        sm: '60px',
        md: '70px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 56px
    3: {
      fontSize: {
        base: '36px',
        sm: '46px',
        md: '56px',
      },
      lineHeight: {
        base: '40px',
        sm: '46px',
        md: '56px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 48px
    4: {
      fontSize: {
        base: '32px',
        sm: '38px',
        md: '34px',
        lg: '48px',
      },
      lineHeight: {
        base: '36px',
        sm: '42px',
        lg: '64px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 38px
    5: {
      fontSize: {
        base: '26px',
        sm: '30px',
        md: '38px',
      },
      lineHeight: {
        base: '35px',
        sm: '41px',
        md: '51px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 36px
    6: {
      fontSize: {
        base: '24px',
        sm: '28px',
        md: '36px',
      },
      lineHeight: {
        base: '32px',
        sm: '36px',
        md: '44px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 30px
    7: {
      fontSize: {
        base: '22px',
        sm: '26px',
        md: '30px',
      },
      lineHeight: {
        base: '30px',
        sm: '34px',
        md: '38px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 24px
    8: {
      fontSize: {
        base: '20px',
        sm: '22px',
        md: '24px',
      },
      lineHeight: {
        base: '28px',
        sm: '30px',
        md: '36px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 22px
    9: {
      fontSize: {
        base: '18px',
        sm: '20px',
        md: '22px',
      },
      lineHeight: {
        base: '28px',
        sm: '32px',
        md: '34px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 20px
    10: {
      fontSize: {
        base: '18px',
        md: '20px',
      },
      lineHeight: {
        base: '28px',
        md: '32px',
      },
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 18px
    11: {
      fontSize: '18px',
      lineHeight: '28px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 16px
    12: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 14px
    13: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 12px
    14: {
      fontSize: '12px',
      lineHeight: '18px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 10px
    15: {
      fontSize: '10px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
    //  default 8px
    16: {
      fontSize: '8px',
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif`,
      fontWeight: 400,
      color: 'gray.500',
    },
  },
};
export default textStyles;
