import React, { forwardRef } from 'react';
import { HStack } from '@chakra-ui/react';
import Input from './Input';
import Button from './Button';
import { FiSearch } from 'react-icons/fi';

const SearchInput = forwardRef(function SearchInput(props, ref) {
  const { showSearchBtn = true, placeholder = 'Search', onSearch = () => {}, ...rest } = props;

  return (
    <HStack spacing="12px" alignItems="center" justifyContent="center" width="100%">
      <Input
        ref={ref}
        placeholder={placeholder}
        leftElement={<FiSearch fontSize="20px" />}
        {...rest}
      />
      {showSearchBtn && (
        <Button colorScheme="secondary" variant="solid" fontSize="14px" onClick={onSearch}>
          Search
        </Button>
      )}
    </HStack>
  );
});

export default SearchInput;
