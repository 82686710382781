import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const red = defineStyle({
  _light: {
    [$startColor.variable]: 'colors.red.100', //changing startColor to red.100
    [$endColor.variable]: 'colors.red.400' // changing endColor to red.400
  },
  _dark: {
    [$startColor.variable]: 'colors.red.800', //changing startColor to red.800
    [$endColor.variable]: 'colors.red.600' // changing endColor to red.600
  }
});

const primary = defineStyle({
  _light: {
    [$startColor.variable]: 'colors.primary.100', //changing startColor to primary.100
    [$endColor.variable]: 'colors.primary.200' // changing endColor to primary.200
  },
  _dark: {
    [$startColor.variable]: 'colors.primary.800', //changing startColor to primary.800
    [$endColor.variable]: 'colors.primary.600' // changing endColor to primary.600
  }
});
export const skeletonTheme = defineStyleConfig({
  variants: { red, primary },
  defaultProps: {
    startColor: 'primary.100',
    endColor: 'primary.200'
  }
});
