import React, { forwardRef } from 'react';
import { Flex, Textarea as ChakraTextarea } from '@chakra-ui/react';
import colors from 'config/theme/colors';
import Text from 'components/Text.js';

const Textarea = forwardRef(function Textarea(props, ref) {
  const { tip, isInvalid, errorMsg, id, label, isRequired, ...rest } = props;
  return (
    <Flex direction="column" width="100%">
      <Text as="label" textStyle="regular.13" color="gray.500" mb="6px" htmlFor={id}>
        {label}
        {isRequired && <sup>*</sup>}
      </Text>
      <ChakraTextarea
        id={id}
        ref={ref}
        borderRadius={'8px'}
        borderColor="gray.100"
        isInvalid={isInvalid}
        _focus={{
          border: '1px solid',
          borderColor: 'primary.300',
          boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`,
          outline: 'none',
          _hover: {
            border: `1px solid ${colors.primary[500]}`,
            outline: 'none',
          },
        }}
        _placeholder={{
          color: 'gray.200',
        }}
        _hover={{
          border: `1px solid ${colors.gray[200]}`,
          outline: 'none',
        }}
        _disabled={{
          opacity: 0.5,
        }}
        _invalid={{
          border: `1px solid ${colors.error[500]}`,
          boxShadow: 'none',
          outline: 'none',
          _active: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
          _focus: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
        }}
        {...rest}
      />
      {tip || isInvalid ? (
        <Text
          textStyle={isInvalid ? 'regular.14' : 'regular.13'}
          color={isInvalid ? 'error.500' : 'gray.400'}
          mt="6px"
        >
          {isInvalid ? errorMsg : tip}
        </Text>
      ) : null}
    </Flex>
  );
});

export default Textarea;
