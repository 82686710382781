import React from 'react';
import Button from './index.jsx';
import { HStack } from '@chakra-ui/react';

export default function ButtonGroup({ data = [], selected, onChange }) {
  const handleSelect = (id) => {
    onChange(id);
  };

  return (
    <HStack spacing="0px" borderRadius="8px">
      {data.map((btn, index) => {
        const isLastItemInBatch = index == data.length - 1;

        return (
          <Button
            key={btn.id}
            colorScheme="secondary"
            variant={'outline'}
            size="sm"
            onClick={() => handleSelect(btn.id)}
            px={{ base: '10px', md: '16px' }}
            height={{ base: '36px !important', md: '40px  !important' }}
            borderLeftRadius={index === 0 ? '8px !important' : '0px !important'}
            borderRightRadius={isLastItemInBatch ? '8px !important' : '0px !important'}
            borderColor={'gray.100'}
            _hover={{
              borderColor: 'gray.200',
            }}
            borderWidth={index === 0 || isLastItemInBatch ? 1 : 0.5}
            bg={selected === btn.id ? 'gray.50' : 'white'}
            boxShadow="none !important"
            fontSize="14px"
          >
            {btn.title}
          </Button>
        );
      })}
    </HStack>
  );
}
