import api from 'services/api.js';
import e from 'constants/endpoints.js';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

/**
 * Endpoint to get Profile
 *
 * @returns {Promise<{data: Profile }>}
 */
const fetchProfile = async () => {
  const { data } = await api.get(e.PROFILE);
  return data;
};
export const useProfile = (options = {}) =>
  useQuery({
    queryKey: ['profile'],
    queryFn: () => fetchProfile(),
    ...options,
  });

/**
 * Endpoint to get user currencyInfo
 *
 * @returns {Promise<{data: userCurrencyInformation }>}
 */
const fetchUserCurrencyInfo = async () => {
  const { data } = await api.get(e.GET_USER_CURRENCY_INFO);
  return data;
};
export const useUserCurrencyInfoQuery = (options = {}) =>
  useQuery({
    queryKey: ['user-currency-info'],
    queryFn: () => fetchUserCurrencyInfo(),
    ...options,
  });

/**
 * Endpoint to get all users
 *
 * @param {string} pageParam
 * @param {number} limit
 * @param {string} query
 *
 * @returns {Promise<{ data: users }>}
 */
const fetchUsers = async (pageParam, limit, query) => {
  const { data } = await api.get(`${e.USERS('')}?page=${pageParam}&limit=${limit}${query}`);
  const totalPages = Math.ceil(data.count / limit);
  return {
    ...data,
    currentPage: pageParam,
    nextPage: pageParam === totalPages ? pageParam : pageParam + 1,
    prevPage: pageParam - 1,
    totalPages: totalPages,
  };
};
export const useUsers = (pageParam = 1, limit = 10, query = '', options) =>
  useInfiniteQuery(['users', pageParam, limit, query], () => fetchUsers(pageParam, limit, query), {
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages && lastPage.nextPage !== lastPage.currentPage)
        return pageParam;

      return false;
    },

    getPreviousPageParam: (firstPage) => {
      if (firstPage.prevPage >= 1 && firstPage.prevPage <= firstPage.totalPages) return pageParam;
      return false;
    },
    keepPreviousData: true,
    ...options,
  });

/**
 * Endpoint to get user details
 *
 * @param {string} id - userId
 *
 * @returns {Promise<{data: user }>}
 */
const fetchUser = async (id) => {
  const { data } = await api.get(e.USERS(id));
  return data;
};
export const useUserQuery = (id, options = {}) =>
  useQuery({
    queryKey: ['user', id],
    queryFn: () => fetchUser(id),
    ...options,
  });
