import React from 'react';
import { AppRouter } from './components/index.js';
import moment from 'moment-timezone';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

function App() {
  moment.tz.setDefault(timeZone);
  return (
    <div className="app">
      <AppRouter />
    </div>
  );
}

export default App;
