const styles = {
  global: {
    body: {
      fontFamily: `'SatoshiRegular', 'DM Sans', sans-serif `,
    },
    heading: {
      heading: `'SatoshiBold', 'DM Sans', sans-serif `,
    },
    a: {
      fontFamily: `'SatoshiMedium', 'DM Sans', sans-serif`,
      textDecoration: 'underline',
      padding: 0,
      _hover: {
        textDecoration: 'underline',
        border: 'none',
      },
      _active: {
        textDecoration: 'underline',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
      },
      _focus: {
        textDecoration: 'underline',
        border: 'none',
        outline: 'none',
      },
    },
    button: {
      fontFamily: `SatoshiMedium, 'DM Sans', sans-serif`,
      borderRadius: '8px',
    },
    input: {
      fontFamily: `SatoshiRegular, 'DM Sans', sans-serif`,
      borderRadius: '8px',
    },
    select: {
      fontFamily: `SatoshiRegular, 'DM Sans', sans-serif`,
      borderRadius: '8px',
    },
  },
};
export default styles;
