import colors from '../colors';

const Select = {
  baseStyle: {
    field: {
      borderRadius: '8px',
      height: '44px',
      border: '1px solid',
      borderColor: 'gray.100',
    },
  },
  sizes: {
    sm: {
      fontWeight: 500,
      height: '40px',
      fontSize: '14px',
      padding: '8px 12px',
    },
    md: {
      fontWeight: 500,
      height: '44px',
      fontSize: '16px',
      padding: '8px 12px',
    },
    lg: {
      fontWeight: 500,
      height: '50px',
      fontSize: '18px',
      padding: '8px 12px',
    },
  },
  variants: {
    outline: {
      field: {
        border: '1px solid',
        borderColor: 'gray.100',
        boxShadow: 'none',
        _invalid: {
          border: `1px solid ${colors.error[500]}`,
          boxShadow: 'none',
          outline: 'none',
          _active: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
          _focus: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
        },
        _hover: {
          border: `1px solid ${colors.gray[200]}`,
          outline: 'none',
        },
        _active: {
          border: '1px solid',
          borderColor: 'primary.300',
          boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`,
          outline: 'none',
        },
        _focus: {
          border: '1px solid',
          borderColor: 'primary.300',
          boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary[50]}`,
          outline: 'none',
          _hover: {
            border: `1px solid ${colors.primary[500]}`,
            outline: 'none',
          },
        },
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    filled: {
      field: {
        bg: 'gray.100',
        _invalid: {
          border: `1px solid ${colors.error[500]}`,
          boxShadow: 'none',
          outline: 'none',
          _active: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
          _focus: {
            border: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
        },
        _hover: {
          bg: 'gray.100',
        },
        _active: {
          border: '1px solid',
          borderColor: 'primary.300',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          outline: 'none',
        },
        _focus: {
          border: '1px solid',
          borderColor: 'primary.300',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          outline: 'none',
        },
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    flushed: {
      field: {
        borderBottom: '1px solid',
        borderBottomColor: 'gray.100',
        _invalid: {
          borderBottom: `1px solid ${colors.error[500]}`,
          boxShadow: 'none',
          outline: 'none',
          _active: {
            borderBottom: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
          _focus: {
            borderBottom: `1px solid ${colors.error[500]}`,
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error[50]}`,
            outline: 'none',
          },
        },
        _hover: {
          borderBottom: `1px solid ${colors.gray[200]}`,
          outline: 'none',
        },
        _active: {
          borderBottom: '1px solid',
          borderBottomColor: 'primary.300',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          outline: 'none',
        },
        _focus: {
          borderBottom: '1px solid',
          borderBottomColor: 'primary.300',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          outline: 'none',
        },
        _disabled: {
          opacity: 0.5,
        },
      },
    },
    unstyled: {
      field: {
        _invalid: {
          color: 'error.500',
        },
      },
    },
  },
};
export default Select;
