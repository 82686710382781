import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import { theme, Fonts } from 'config/theme/index.js';
import { UserProvider } from 'hooks/context/useUser';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2 } },
});

export default function Provider({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <BrowserRouter>
          <UserProvider>{children}</UserProvider>
          <ToastContainer />
        </BrowserRouter>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
