const breakpoints = {
  base: 0,
  sm: 600,
  md: 800,
  lg: 1024,
  xl: 1200,
  '2xl': 1440,
};

export default breakpoints;
