import React from 'react';
import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
        @font-face {
          font-family: "SatoshiBold";
          src: local("SatoshiBold"), url("/fonts/Satoshi-Bold.ttf") format("truetype");
          font-weight: 700;
        }
        
        @font-face {
          font-family: "SatoshiMedium";
          src: local("SatoshiMedium"), url("/fonts/Satoshi-Medium.ttf") format("truetype");
          font-weight: 500;
        }

        @font-face {
          font-family: "SatoshiRegular";
          src: local("SatoshiRegular"), url("/fonts/Satoshi-Regular.ttf") format("truetype");
          font-weight: 400;
        }
    `}
  />
);

export default Fonts;
