import React from 'react';
import { onlineManager } from '@tanstack/react-query';
import { toastError } from 'components/Toast';
import { createContext, useContext, useEffect, useReducer } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { useProfile, useUserCurrencyInfoQuery } from 'hooks/queries/useProfile';

const UserContext = createContext();

const initialUserState = {
  user: {},
  business: {},
  currency: {
    name: 'USD',
    symbol: '$',
  },
};

const userReducer = (state, action) => {
  return {
    ...state,
    ...action,
  };
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialUserState);
  const isOnline = onlineManager.isOnline();
  const { token } = useLocalStorage();

  useEffect(() => {
    if (!isOnline) {
      toastError(`You're currently offline`);
    }
  }, [isOnline]);

  const value = { state, dispatch };

  const { refetch } = useUserCurrencyInfoQuery({
    onSuccess: (data) => {
      dispatch({
        ...state,
        currency: {
          name: data.currencyInfo.currency,
          symbol: data.currencyInfo.currencySymbol,
          ...data,
        },
      });
    },
  });

  // eslint-disable-next-line no-unused-vars
  const { isLoading: isLoadingUser } = useProfile({
    enabled: token !== '' && token !== null ? true : false,
    onSuccess: (data) => {
      refetch();
      dispatch({
        ...state,
        user: data,
        business: data.businesses[0],
      });
    },
  });

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    console.warn('useUser must be used within a UserProvider');
  }

  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}

export { initialUserState, UserContext, UserProvider, useUser };
