import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    // this will style the MenuButton component
    bg: 'white',
    color: 'gray.500',
    _hover: {
      bg: 'gray.25'
    }
  },
  list: {
    // this will style the MenuList component
    py: 0,
    border: `1px solid ${colors.gray[100]}`,
    bg: 'white'
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    borderRadius: '0px !important',
    marginBottom: '4px !important',
    color: 'gray.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
      bg: 'primary.100',
      _disabled: {
        background: 'gray.25',
        color: 'gray.300',
        opacity: 1,
        cursor: 'not-allowed'
      }
    },
    _focus: {
      textDecoration: 'none',
      bg: 'primary.100'
    },
    _disabled: {
      textDecoration: 'none',
      background: 'gray.25',
      color: 'gray.300',
      opacity: 1,
      cursor: 'not-allowed'
    }
  }
});
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({ baseStyle });
