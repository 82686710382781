import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Tag as ChakraTag } from '@chakra-ui/react';

const Tag = forwardRef(function Tag(props, ref) {
  const { children, ...rest } = props;

  return (
    <ChakraTag ref={ref} {...rest}>
      {children}
    </ChakraTag>
  );
});

export default Tag;

Tag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
