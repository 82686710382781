import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loader } from 'components/index.js';
import ProtectedRoute from 'components/Shared/ProtectedRoute';

const Home = lazy(() => import('screens/Home'));
const Creators = lazy(() => import('screens/Creators'));
const CreatorOverview = lazy(() => import('screens/Creators/CreatorOverview'));
const CreatorDetails = lazy(() => import('screens/Creators/CreatorDetails'));
const CreatorExperienceDetails = lazy(() => import('screens/Creators/CreatorExperienceDetails'));
const CreatorExperienceSchedules = lazy(() =>
  import('screens/Creators/CreatorExperienceSchedules')
);
const CreatorExperienceScheduleBookings = lazy(() =>
  import('screens/Creators/CreatorExperienceScheduleBookings')
);

const Users = lazy(() => import('screens/Users'));
const Waitlist = lazy(() => import('screens/Users/Waitlist'));

const Community = lazy(() => import('screens/Community'));
const Payments = lazy(() => import('screens/Payments'));
const PaymentDetails = lazy(() => import('screens/Payments/PaymentDetails'));

const Settings = lazy(() => import('screens/Settings'));

const Login = lazy(() => import('screens/Login'));
const NotFound = lazy(() => import('screens/NotFound'));

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          <Route path="/creators" element={<Creators />} />
          <Route path="/creators/:id" element={<CreatorOverview />} />
          <Route path="/creators/:id/details" element={<CreatorDetails />} />
          <Route
            path="/creators/:id/experiences/:experienceId"
            element={<CreatorExperienceDetails />}
          />
          <Route
            path="/creators/:id/experiences/:experienceId/schedules"
            element={<CreatorExperienceSchedules />}
          />
          <Route
            path="/creators/:id/experiences/:experienceId/schedules/:scheduleId"
            element={<CreatorExperienceScheduleBookings />}
          />

          <Route path="/users" element={<Users />} />
          <Route path="/users/waitlist" element={<Waitlist />} />
          <Route path="/community" element={<Community />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/payments/:id/businesses/:businessId" element={<PaymentDetails />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
