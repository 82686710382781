import { useEffect, useState } from 'react';
import { getLoginIdentifier, getPreviousRoute, getAuthToken } from 'utils/auth';

/**
 * Hook to get items stored in local storage
 *
 * @returns { token: string,  previousPath: string, isLoggedIn: string, getAsyncStorageItems: func }
 */
export default function useLocalStorage() {
  const [state, setState] = useState({
    token: '',
    previousPath: '',
    isLoggedIn: '',
    isLoading: true,
  });

  useEffect(() => {
    getLocalStorageItems();

    return () => {
      setState((state) => state);
    };
  }, []);

  async function getLocalStorageItems() {
    try {
      const token = await getAuthToken();
      const previousPath = await getPreviousRoute();
      const isLoggedIn = await getLoginIdentifier();

      setState({
        token,
        previousPath,
        isLoggedIn,
        isLoading: false,
      });
    } catch (error) {
      console.warn(error);
    }
  }

  return { ...state, getLocalStorageItems };
}
