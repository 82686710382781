import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'rgba(63, 63, 63, 0.8)', //change the background
  },
  dialog: {
    borderRadius: 'md',
    bg: 'white',
  },
  closeButton: {
    _focusVisible: {
      boxShadow: 'none',
    },
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
