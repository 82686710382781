import { TOKEN_IDENTIFIER, CURRENT_ROUTE, EXPITRA_LOGIN_IDENTIFIER } from 'constants/strings.js';

export const storeAuthToken = (token) => localStorage.setItem(TOKEN_IDENTIFIER, token);

export const getAuthToken = () => localStorage.getItem(TOKEN_IDENTIFIER);

export const removeAuthToken = () => localStorage.removeItem(TOKEN_IDENTIFIER);

export const storeCurrentRoute = (path) => localStorage.setItem(CURRENT_ROUTE, path);

export const getPreviousRoute = () => localStorage.getItem(CURRENT_ROUTE);

export const removePreviousRoute = () => localStorage.removeItem(CURRENT_ROUTE);

export const storeLoginIdentifier = (isLoggedIn) =>
  localStorage.setItem(EXPITRA_LOGIN_IDENTIFIER, isLoggedIn);

export const getLoginIdentifier = () => localStorage.getItem(EXPITRA_LOGIN_IDENTIFIER);

export const removeLoginIdentifier = () => localStorage.removeItem(EXPITRA_LOGIN_IDENTIFIER);
