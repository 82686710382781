import React from 'react';
import { Loader } from 'components';
import { useUser } from 'hooks/context/useUser';
import useLocalStorage from 'hooks/useLocalStorage';
import { storeCurrentRoute, storeLoginIdentifier } from 'utils/auth';

const ProtectedRoute = ({ children }) => {
  const { isLoading, isLoggedIn } = useLocalStorage();
  const { state } = useUser();

  if (isLoading) {
    return <Loader />;
  } else if (!isLoading && isLoggedIn !== 'true') {
    storeCurrentRoute(window.location.pathname); //save current route
    storeLoginIdentifier('false');

    window.location.replace('/login');

    return;
  } else if (Object.keys(state.user)?.length !== 0 && state.user?.role !== 'admin') {
    window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);

    return;
  } else {
    return children;
  }
};

export default ProtectedRoute;
