import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import colors from 'config/theme/colors';

const Card = ({ width, height = '100%', backgroundColor, children, footer, ...rest }) => {
  return (
    <Stack
      border={`1px solid ${colors.gray[100]}`}
      boxShadow="0px 4px 10px -2px rgba(102, 102, 102, 0.1)"
      borderRadius="8px"
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      justifyContent="space-between"
      {...rest}
    >
      <Box padding="24px" minHeight={'120px'} width="100%">
        {children}
      </Box>
      {footer && (
        <Box borderTopWidth={1} w="100%" borderTopColor="gray.100">
          {footer}
        </Box>
      )}
    </Stack>
  );
};

export default Card;
